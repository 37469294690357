import React, { useState } from "react"
import DetailTable from "./DetailTable"
import "./layout.css"



export default function DetailView({ country }) {

const [ detailCategory, setDetailCategory ] = useState('Confirmed');

return (

  <div>
      <div className="region">
      <h3>
          {detailCategory} by Region:
      </h3>
      <div className="buttons">
      <button className="button" onClick={() => setDetailCategory('Confirmed')} >
          Confirmed
      </button>
      <button className="button" onClick={() => setDetailCategory('Recovered')} >
          Recovered
      </button>
      <button className="button" onClick={() => setDetailCategory('Deaths')} >
          Deaths
      </button>
      </div>
      </div>
      <DetailTable category={detailCategory.toLowerCase()} country={country} />
  </div>

  )
}
