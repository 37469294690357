import React from "react"
import CountUp from "react-countup"


export default function Card({ title, number, info}) {
  return ( 
  <div className="card">
     <h2>
        <CountUp
        end={number}
        separator=','
        duration={0.7}
        />
    </h2>
    <p className={info}>{title}</p>
    

  </div>
  )

}
