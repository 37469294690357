const countries = {
    'Australia': "AU",
    'Brazil': "BR",
    'Canada': "CA",
    'China': "CN",
    'Germany': "DE",
    'India': "IN",
    'Italy': "IT",
    'Japan': "JP",
    'Mexico': "MX",
    'Netherlands': "NL",
    'Pakistan': "PK",
    'Peru': "PE",
    'Spain': "ES",
    'Ukraine': "UA",
    'United Kingdom': "GB",
    'United States': 'US',
  }

  export default countries