import React from "react"
import Header from "../components/Header"
import Api_Url from "../api_url/Api_Url"
import Stats from "../components/Stats"
import DailyChart from "../components/DailyChart"
import CountrySelector from "../components/CountrySelector"
import { Container, Row, Col, } from "react-bootstrap";
import "../components/layout.css"



export default function Home() {
  return (
    <Container fluid className="page">
    <div>
      <Header />
      <div className="section">
      <Stats url={Api_Url} sectionTitle='Global Cases'/>
      <div className="graph">
      <DailyChart />
      </div>
      </div>
      <CountrySelector />
    
    
    
    
    </div>
    </Container>

  )
}
