import React, { useState } from "react"
import Api_Url from "../api_url/Api_Url"
import useAPI from "./Hooks/useAPI"
import "./layout.css"
import CountUp from "react-countup"


export default function DetailTable({ category, country }) {

// const { data, loading, error } = useAPI(`${Api_Url}/countries/${country}/confirmed`)
if ( country === "United States" ) {
    country = "US"
}

const { data, loading, error } = useAPI({url: `${Api_Url}/countries/${country}/confirmed?level=provinceState`})

if ( !data ) {
    return null
}

const confirmedData = data.map((x) => x.confirmed)
const recoveredData = data.map((x) => x.recovered)
const deathsData = data.map((x) => x.deaths)
function add(accumulator, a) {
    return accumulator + a;
}

const totalPercentageConfirmed = data.map((x) => [x[category]/confirmedData.reduce(add,0)*100])
const totalPercentageConfirmedformat = totalPercentageConfirmed.map((x) => parseFloat(x).toFixed(2)+'%')

const totalPercentageRecovered = data.map((x) => [x[category]/recoveredData.reduce(add,0)*100])
const totalPercentageRecoveredformat = totalPercentageRecovered.map((x) => parseFloat(x).toFixed(2)+'%')

const totalPercentageDeaths = data.map((x) => [x[category]/deathsData.reduce(add,0)*100])
const totalPercentageDeathsformat = totalPercentageDeaths.map((x) => parseFloat(x).toFixed(2)+'%')


const confirmed = data.map((x, i) => [x.provinceState, x[category], totalPercentageConfirmedformat[i]])
const recovered = data.map((x, i) => [x.provinceState, x[category], totalPercentageRecoveredformat[i]])
const deaths = data.map((x, i) => [x.provinceState, x[category], totalPercentageDeathsformat[i]])

console.log(confirmed)




if ( category === 'confirmed' ) {
    return (
        <ul className="confirmedlist">
            {confirmed.map((x, i) => 
                <li className="confirmedlistitem" style={{width: '100%'}} key={x}>
                    <span className="region">{x[0]}:</span>
                    <span className="number"><CountUp end={x[1]} separator=','/></span>
                    <span className="confirmedpercent">{x[2]}</span>
                </li>)}
        </ul>
    )
  }

if ( category === 'recovered' ) {
    return (
        <ul className="recoveredlist">
            {recovered.map((x, i) => 
                <li className="recoveredlistitem" style={{width: '100%'}} key={x}>
                    <span className="region">{x[0]}:</span>
                    <span className="number"><CountUp end={x[1]} separator=','/></span>
                    <span className="recoveredpercent">{x[2]}</span>
                </li>)}
        </ul>
    )
  }
  
if ( category === 'deaths' ) {
    return (
        <ul className="deathlist">
            {deaths.map((x, i) => 
                <li className="deathlistitem" style={{width: '100%'}} key={x}>
                    <span className="region">{x[0]}:</span>
                    <span className="number"><CountUp end={x[1]} separator=','/></span>
                    <span className="deathspercent">{x[2]}</span>
                </li>)}
        </ul>
    )
  }

}
