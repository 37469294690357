import React from "react"
import Card from "./Card"
import Api_Url from "../api_url/Api_Url"
import useAPI from "./Hooks/useAPI"
import "./layout.css"

const formatDate = ( date ) => {
    const opts = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }
    return new Date( date ).toLocaleString('en-us', opts);
  }


export default function Stats({ url, sectionTitle}) {
    const { data, loading, error } = useAPI({ url });
    if ( loading ) {
        return <p>Loading...</p> 
      }
    return (
    <div>
        {data && (
        <div>
          <h2 className="sectiontitle">{sectionTitle}</h2> 
          <p className="lastupdate"> Last Updated: {formatDate(data.lastUpdate)}</p>  
          <div className="cards">
            <Card title='Confirmed' number={data.confirmed.value} info="Confirmed"/>
            <Card title='Recovered' number={data.recovered.value} info="Recovered"/>
            <Card title='Deaths' number={data.deaths.value} info="Deaths"/>
          </div>
        </div>
    )}
    
    </div>
  )

}
