import React from "react"
import logo from "./8057.jpg"
import "./layout.css"

export default function Header() {
  return <div className="Header">
      <img className="image" src={logo} alt="image" />;
      <h1 className="title">COVID-19 Tracker</h1>
      <p className="source">Data via <a className="link" href="https://github.com/mathdroid/covid-19-api">Mathdroid's COVID-19 API</a></p>

  </div>


}
