import React, { useState } from "react"
import Select from "react-select"
import countries from "./countries"
import Stats from "./Stats"
import Api_Url from "../api_url/Api_Url"
import DetailView from "./DetailView"




const customStyles = {
    control: (base, state) => ({
      ...base,
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px',
      fontWeight: '400',
      padding: '8px',
      borderRadius: '6px',
      transition: 'box-shadow 100ms ease'
    })}



function ValueSorter({ countries }) {
    
    const countriesFormatted = Object.entries( countries )
        .map( ([ label, value ]) => ({ value, label }))
    
     
    return countriesFormatted

    
  }

const countriesOptions = ValueSorter({ countries })
const defaultValue = {label: "United States", value: "US"}


export default function CountrySelector() {
const [ selected, setSelected ] = useState(defaultValue)

return (
    <div>
        <div className="select">
            <Select 
                options={countriesOptions}
                styles={customStyles}
                defaultValue={selected}
                onChange={setSelected}
            />
        </div>
        <div className="section">
            <Stats url={ `${Api_Url}/countries/${selected.value}`} sectionTitle={selected.label}/>
        </div>
        <div>
            <DetailView country={selected.label}/>
        </div>
    </div>
  )
}