import React from "react"
import MakeChart from './ChartView'
import useAPI from './Hooks/useAPI'
import Api_Url from "../api_url/Api_Url"
import "./layout.css"



export default function DailyChart() {

    const { data, loading, error } = useAPI({url: `${ Api_Url }/daily` });
    if ( loading || !data ) {
        return null;
      }
    console.log(data.length)
    if ( data && data.length ) {
        const chartData = data.map( x => ({ confirmed: x.confirmed.total, deaths: x.deaths.total, date: x.reportDate }) );
        return (
            <div className="chart">
            {data && (
                <div>
                    <MakeChart chartData={chartData}/>
                    
                </div>
            )}
            </div>
        )
    }
}