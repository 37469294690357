import React, { useEffect, useRef } from "react"
import Chart from 'chart.js'




export default function MakeChart({ chartData }) {

    const config = {
        type: 'line',
        data: {
        labels: [...chartData.map( x => x.date )],
        datasets: [
            { 
            data: [...chartData.map( x => x.deaths )],
            label: "Deaths",
            borderColor: "#e192a5",
            backgroundColor: 'rgba(225, 146, 165, 0.5)',
            // pointBackgroundColor: 'fbd0d2',
            pointRadius: 1,
            fill: true,
            borderWidth: 1
            },
            { 
            data: [...chartData.map( x => x.confirmed )],
            label: "Confirmed",
            borderColor: "hsla( 42, 90%, 70%, 1 )",
            backgroundColor: 'rgba( 249, 214, 133, 0.5 )',
            fill: true,
            pointBackgroundColor: '#f9e6ba',
            // pointBorderColor: '#a54c1f',
            pointRadius: 1,
            borderWidth: 1
            }
        ]
        },
        
        options: {
            aspectRatio: 2.75,
            legend: {
              display: true,
              position: 'bottom',
              align: 'end',
              labels: {
                fontStyle: 'bold',
                // fontColor: '#fff'
              }        
            },
        
        scales: {
            xAxes: [{
              type: 'time',
              time: {
                displayFormats: {
                  'millisecond': 'MMM DD',
                  'second': 'MMM DD',
                  'minute': 'MMM DD',
                  'hour': 'MMM DD',
                  'day': 'MMM DD',
                  'week': 'MMM DD',
                  'month': 'MMM DD',
                  'quarter': 'MMM DD',
                  'year': 'MMM DD'
                }
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 12,
                // maxRotation: 0
                // fontColor: 'rgba( 255, 255, 255, 0.7 )'
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              ticks: {
                callback: val => {
                  if ( val > 0 && val >= 1000 && val < 1000000 ) {
                    return (val/1000).toLocaleString() + 'K'
                  }
                  if ( val > 0 && val >= 1000000 ) {
                    return ( val/1000000 ) + 'M'
                  }
                  return val;
                },
                autoSkipLimit: true,
                maxTicksLimit: 7,
                // fontColor: 'rgba( 255, 255, 255, 0.7 )'
              },
              gridLines: {
                color: 'rgba( 0, 0, 0, 0.05 )',
                zeroLineColor: 'rgba( 0, 0, 0, 0.05 )'
              }
            }]
            }
          }
        }
    const chartContainer = useRef( null );

    useEffect( () => {
        new Chart( chartContainer.current, config );
    }, [chartContainer])
        
        return (
            <canvas
            ref={chartContainer}
            id="myChart"
            width="666"
            height="241"
            />
        )
        }